import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import ReduxToastr from 'react-redux-toastr';
import { Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './component/common/header.js';
import Footer from './component/common/footer.js';
import { Provider } from 'react-redux';
import { store } from './redux/storeConfig/store';
import Spinner from './component/common/commonLoader';
import history from '../src/app/history';
import { CookiesProvider } from 'react-cookie';
import 'flatpickr/dist/themes/dark.css';
const LazyApp = lazy(() => import('./Root.js'));

const jsx = (
  <React.StrictMode>
    <Router history={history} basename={'/'}>
      <CookiesProvider>
        <Provider store={store}>
          <Header />
          <Suspense fallback={<Spinner />}>
            <LazyApp />
            <ReduxToastr
              timeOut={4000}
              newestOnTop={false}
              preventDuplicates
              position="top-right"
              transitionIn="fadeIn"
              transitionOut="fadeOut"
              progressBar
              closeOnToastrClick
            />
          </Suspense>
        </Provider>
      </CookiesProvider>
      <Footer />
    </Router>
  </React.StrictMode>
);
ReactDOM.render(jsx, document.getElementById('root'));

// let hasRendered = false;

const renderApp = (user = '') => {
  // if (!hasRendered) {
  // if(user)
  //call socket here
  ReactDOM.render(jsx, document.getElementById('root'));
  // registerServiceWorker();
  // hasRendered = true;
  // }
};
serviceWorker.register();
const renderLogin = () => {
  // store.dispatch(setIsAuthenticated(false));
  renderApp();
  // history.push('/');
};

/**
 *
 * @param {*} user
 * @todo - move this to redux
 */
// const handleToken = async (user) => {
//   const roleResponse = await api(`v1/organization/${user.organizationId}/user/${user.id}/role`, {}, "get");
//   if (roleResponse.status === 200) {
//     await subscribeToAircraft({ id: user.id });
//     store.dispatch(setPermission(roleResponse.data.organizationRole.web_access));
//     return renderApp(user);
//   }
//   store.dispatch(logout());
//   return renderLogin();
// };

try {
  let user = localStorage.getItem('member');
  // store.dispatch(getLoginBackground());
  if (user) {
    user = JSON.parse(user);
    let check = user.status === 'completed' && user.membership.length;
    // console.log(user, check, "member");
    store.dispatch({
      type: 'LOGIN_WITH_JWT',
      payload: { loggedInUser: user, loggedInWith: 'jwt' },
    });
    store.dispatch({
      type: 'SET_LOGIN_FLAG',
      payload: { data: true },
    });

    if (
      !history.location.pathname.includes('confirmation') &&
      !history.location.pathname.includes('cancel') &&
      !history.location.pathname.includes('notify') &&
      !history.location.pathname.includes('payment') &&
      !history.location.pathname.includes('billing_entity') &&
      !history.location.pathname.includes('verify') &&
      !history.location.pathname.includes('confirmMotivation')
    ) {
      if (user.status === 'ready' && !user.membership.length) {
        history.push({
          pathname: `/member/registration/step_form/${user.id}`,
          state: {
            name: user.name,
            userEmail: user.email,
            password: user.password,
          },
        });
      }
      // else if (user.status === "completed" && user.payerType === "group") {
      //   history.replace(`/member/group_waiting`);
      // }
      else if (
        user.status === 'completed' &&
        user.membership.length &&
        user.payerType === 'individual'
      ) {
        // console.log("correct");
        history.push(`/member-registration/payment/${user.id}`);
      } else if (
        user.membership[0] &&
        user.membership[0].status === 'expired'
      ) {
        history.push('/member/my_membership');
      } else {
        history.push(history.location.pathname);
      }
      if (history.location.pathname === '/') {
        history.push('/member/dashboard');
      }
    } else {
      renderLogin();
    }
  } else {
    // console.log("hyyyyy");
    renderLogin();
  }
} catch (e) {
  renderLogin();
}
